import {Permission, PERMISSION_ADDRESSBOOK, PERMISSION_CALLLIST, PERMISSION_KEYS, PERMISSION_MODULEKEY, PERMISSION_REDIRECTION} from '@/services/Permissions'

export type Account = {
	accountId: number
	firstname: string
	lastname: string
	federationname: string
	primaryInternalPhoneNumber: string
	displayInformation: string
	local: boolean
	type: 'USER' | 'GROUP' | 'REMOTE_USER' | 'REMOTE_GROUP'
}

export type AccountSearchResult = {
	id: number | string
	name: string
	phoneNumber: string
}

// typescript voodoo to have both: the possible types as value and an union type based on the values
export const ALL_FORWARD_TYPES = ['ALWAYS', 'BUSY', 'TIMEOUT'] as const
export type ForwardType = (typeof ALL_FORWARD_TYPES)[number]

export type AddressbookRequest = 'CONTACTLIST' | 'CONTACTSEARCH'
export type CallListRequest = 'INCOMING' | 'OUTGOING' | 'MISSED'

export type FunctionKeyType =
	'BUSYLAMPFIELD'
	| 'QUICKDIAL'
	| 'DONOTDISTURB'
	| 'COMPLETIONOFCALLSTOBUSYSUBSCRIBER'
	| 'PARKANDORBIT'
	| 'PHONEDTMF'
	| 'SEPARATOR'
	| 'SIGNALNUMBER'
	| 'PHONEGENERICURL'
	| 'ADDRESSBOOK'
	| 'PHONECALLLIST'
	| 'MODULEACTIVATION'
	| 'GROUPLOGIN'
	| 'FORWARD'
	| 'FORWARDNUMBER'
	| 'FORWARDTOTARGET'

export type FunctionKeyConfig = {
	activateModuleIds?: Array<string>
	addressBookFolderName?: string
	addressbookRequest?: AddressbookRequest
	blfAccountId?: number
	callListRequest?: CallListRequest
	directCallTargetnumber?: string
	displayNumberId?: number
	dtmf?: string
	forwardTarget?: string
	forwardTargetType?: 'VOICEMAIL' | 'PHONENUMBER'
	forwardType?: ForwardType
	genericURL?: string
	groupIds?: Array<number>
	name: string
	poNumber?: string
	redirectNumberIds?: Array<number>
}

export type FunctionKeyState = 'PROVISIONED' | 'ERROR' | 'CREATED' | 'TEMPLATE'

export type FunctionKey = {
	uuid: string
	accountId: string
	type: FunctionKeyType
	config?: FunctionKeyConfig
	position: number
	state: FunctionKeyState
	permissions: Permission[]
}

export type FunctionKeyOption = {
	id: number
	name: string
	checked: boolean
	disabled: boolean
}

export type LibraryType = 'FAVORITE' | 'FUNCTION' | 'PHONE_ONLY'

export type FunctionKeyTemplate = {
	capacity: number,
	short: string,
	type: FunctionKeyType,
	configKeys: Array<keyof FunctionKeyConfig>
	permissions: Permission[]
	library: LibraryType
}

export const TEMPLATE_BUSYLAMPFIELD: FunctionKeyTemplate = {
	capacity: 200,
	short: 'blf',
	type: 'BUSYLAMPFIELD',
	configKeys: ['name', 'blfAccountId'],
	permissions: [PERMISSION_KEYS],
	library: 'FAVORITE'
}

export const TEMPLATE_QUICKDIAL: FunctionKeyTemplate = {
	capacity: 200,
	short: 'quick',
	type: 'QUICKDIAL',
	configKeys: ['name', 'directCallTargetnumber'],
	permissions: [PERMISSION_KEYS],
	library: 'FAVORITE'
}

export const TEMPLATE_DONOTDISTURB: FunctionKeyTemplate = {
	capacity: 1,
	short: 'dnd',
	type: 'DONOTDISTURB',
	configKeys: ['name'],
	permissions: [PERMISSION_KEYS],
	library: 'FUNCTION'
}

export const TEMPLATE_COMPLETIONOFCALLSTOBUSYSUBSCIBER: FunctionKeyTemplate = {
	capacity: 1,
	short: 'ccbs',
	type: 'COMPLETIONOFCALLSTOBUSYSUBSCRIBER',
	configKeys: ['name'],
	permissions: [PERMISSION_KEYS],
	library: 'FUNCTION'
}

export const TEMPLATE_PARKANDORBIT: FunctionKeyTemplate = {
	capacity: 100,
	short: 'pao',
	type: 'PARKANDORBIT',
	configKeys: ['name', 'poNumber'],
	permissions: [PERMISSION_KEYS],
	library: 'PHONE_ONLY'
}

export const TEMPLATE_PHONEDTMF: FunctionKeyTemplate = {
	capacity: 200,
	short: 'dtmf',
	type: 'PHONEDTMF',
	configKeys: ['name', 'dtmf'],
	permissions: [PERMISSION_KEYS],
	library: 'PHONE_ONLY'
}

export const TEMPLATE_SEPARATOR: FunctionKeyTemplate = {
	capacity: 200,
	short: 'separator',
	type: 'SEPARATOR',
	configKeys: ['name'],
	permissions: [PERMISSION_KEYS],
	library: 'PHONE_ONLY'
}

export const TEMPLATE_GROUPLOGIN: FunctionKeyTemplate = {
	capacity: 200,
	short: 'group',
	type: 'GROUPLOGIN',
	configKeys: ['name', 'groupIds'],
	permissions: [PERMISSION_KEYS],
	library: 'FUNCTION'
}

export const TEMPLATE_ADDRESSBOOK: FunctionKeyTemplate = {
	capacity: 200,
	short: 'addressbook',
	type: 'ADDRESSBOOK',
	configKeys: ['name', 'addressBookFolderName', 'addressbookRequest'],
	permissions: [PERMISSION_KEYS, PERMISSION_ADDRESSBOOK],
	library: 'PHONE_ONLY'
}

export const TEMPLATE_FORWARD: FunctionKeyTemplate = {
	capacity: ALL_FORWARD_TYPES.length,
	short: 'fwd',
	type: 'FORWARD',
	configKeys: ['name', 'forwardType'],
	permissions: [PERMISSION_KEYS, PERMISSION_REDIRECTION],
	library: 'FUNCTION'
}

export const TEMPLATE_FORWARDNUMBER: FunctionKeyTemplate = {
	capacity: 200,
	short: 'fwdnumber',
	type: 'FORWARDNUMBER',
	configKeys: ['name', 'redirectNumberIds'],
	permissions: [PERMISSION_KEYS, PERMISSION_REDIRECTION],
	library: 'FUNCTION'
}

export const TEMPLATE_FORWARDTOTARGET: FunctionKeyTemplate = {
	capacity: 200,
	short: 'fwdtarget',
	type: 'FORWARDTOTARGET',
	configKeys: ['name', 'forwardTarget', 'forwardTargetType', 'redirectNumberIds'],
	permissions: [PERMISSION_KEYS, PERMISSION_REDIRECTION],
	library: 'FUNCTION'
}

export const TEMPLATE_MODULEACTIVATION: FunctionKeyTemplate = {
	capacity: 200,
	short: 'modules',
	type: 'MODULEACTIVATION',
	configKeys: ['name', 'activateModuleIds'],
	permissions: [PERMISSION_KEYS, PERMISSION_MODULEKEY],
	library: 'FUNCTION'
}

export const TEMPLATE_PHONECALLLIST: FunctionKeyTemplate = {
	capacity: 3,
	short: 'calllist',
	type: 'PHONECALLLIST',
	configKeys: ['name', 'callListRequest'],
	permissions: [PERMISSION_KEYS, PERMISSION_CALLLIST],
	library: 'PHONE_ONLY'
}

export const TEMPLATE_PHONEGENERICURL: FunctionKeyTemplate = {
	capacity: 200,
	short: 'url',
	type: 'PHONEGENERICURL',
	configKeys: ['name', 'genericURL'],
	permissions: [PERMISSION_KEYS],
	library: 'PHONE_ONLY'
}

export const TEMPLATE_SIGNALNUMBER: FunctionKeyTemplate = {
	capacity: 200,
	short: 'signalling',
	type: 'SIGNALNUMBER',
	configKeys: ['name', 'displayNumberId'],
	permissions: [PERMISSION_KEYS],
	library: 'FUNCTION'
}

export const ALL_TEMPLATES = [
	TEMPLATE_BUSYLAMPFIELD,
	TEMPLATE_QUICKDIAL,
	TEMPLATE_FORWARDNUMBER,
	TEMPLATE_FORWARD,
	TEMPLATE_FORWARDTOTARGET,
	TEMPLATE_GROUPLOGIN,
	TEMPLATE_DONOTDISTURB,
	TEMPLATE_COMPLETIONOFCALLSTOBUSYSUBSCIBER,
	//TEMPLATE_PARKANDORBIT,
	TEMPLATE_SIGNALNUMBER,
	TEMPLATE_MODULEACTIVATION,
	TEMPLATE_ADDRESSBOOK,
	TEMPLATE_PHONECALLLIST,
	TEMPLATE_PHONEGENERICURL,
	TEMPLATE_PHONEDTMF,
	TEMPLATE_SEPARATOR
]

export type GroupLogin = {
	activated: boolean
	groupId: number
	groupname: string
}

export type PhoneNumber = {
	assignedGroupAccountId?: number
	assignedModuleInstanceId?: string
	assignedServiceId?: number
	assignedUserAccountId?: number
	exitCode?: string
	id: number
	localPrefix?: string
	nationalPrefix?: string
	number: string
	numberBlockId?: number
	type: 'INVALID' | 'INTERNAL' | 'EXTERNAL' | 'RESERVED' | 'NT' | 'DISPLAY' | 'FXO'
}

export type PhoneNumberConfig = {
	possibleSignalnumbers: Array<PhoneNumber>
	primaryExternalNumberId: number
	primaryInternalNumberId?: number
	signalingNumberId?: number
}

export type VoicemailBoxOption = {
	id: number
	name: string
	owner?: boolean
	group?: boolean
}

export type ModuleOption = {
	activated: boolean
	moduleId: string
	name: string
}

export const getTemplateForType = (type?: string) => ALL_TEMPLATES.find(t => t.type === type)
export const longToShortType = (type?: string) => ALL_TEMPLATES.find(t => t.type === type)?.short
